@import "colors";
@import "fonts";
@import url(//db.onlinewebfonts.com/c/623c7118249e082fe87a78e08506cb4b?family=Segoe+UI);

@font-face {
    font-family: 'Segoe UI';
    src: url('/surface/Assets/css/fonts/all/normal/segoeui.eot');
    src: url('/surface/Assets/css/fonts/all/normal/segoeui.eot?#iefix') format('embedded-opentype');
    src: url('/surface/Assets/css/fonts/all/normal/segoeui.woff') format('woff');
    src: url('/surface/Assets/css/fonts/all/normal/segoeui.svg#SegoeUI') format('svg');
    font-weight: normal;
    font-style: normal; }

.sharedTemplateList {
    font-family: $title-font;
    font-size: 14px;
    color: $grey;

    .list-group-item:first-child {
        color: $text; }

    .delete {
        color: $lt-grey; }

    .delete:hover {
        color: $warning; } }

.tempActionWrapper {
    display: flex;
    position: relative;
    float: right;
    right: 0px;

    .newButton {
        cursor: pointer;
        height: 40px;
        width: 70px; }

    .dotButton {
        position: relative;
        left: 20px;
        cursor: pointer;

        img {
            width: 36px;
            height: 40px; } } }
